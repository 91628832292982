<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Client Updates" active>
          <b-card-text>
            <div
              v-for="(update, index) in updates"
              :key="index"
              class="updateCard mb-3"
              @click="openUrl(update.url)"
            >
              <div class="row text-left">
                <div class="col-5">
                  <div class="row">
                    <div class="col-5">
                      <p class="label">Publisher:</p>
                      <p>{{ update.name }}</p>
                    </div>
                    <div class="col-7">
                      <p class="label">Email:</p>
                      <p>{{ update.email }}</p>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col">
                      <p class="label">Updated At:</p>
                      <p>{{ update.createdAt }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-7">
                  <p class="label">Message:</p>
                  <p>{{ update.message }}</p>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="Server Updates">
          <b-card-text>
            <div
              v-for="(update, index) in updatesServer"
              :key="index"
              class="updateCard mb-3"
              @click="openUrl(update.url)"
            >
              <div class="row text-left">
                <div class="col-5">
                  <div class="row">
                    <div class="col-5">
                      <p class="label">Publisher:</p>
                      <p>{{ update.name }}</p>
                    </div>
                    <div class="col-7">
                      <p class="label">Email:</p>
                      <p>{{ update.email }}</p>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col">
                      <p class="label">Updated At:</p>
                      <p>{{ update.createdAt }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-7">
                  <p class="label">Message:</p>
                  <p>{{ update.message }}</p>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import UpdatesService from "@/services/UpdatesService.js";

export default {
  name: "UpdatesView",
  components: {},
  data() {
    return {
      updates: [],
      updatesServer: [],
    };
  },
  async mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.$emit("loading");
      let data = await UpdatesService.getUpdates().catch((err) => {
        console.log(err);
        this.$notify({
          title: "ERROR",
          type: "danger",
          text: "You do not have permissions to view updates!",
        });
        data = [];
      });
      this.updates = data.updates;
      this.updatesServer = data.updatesServer;
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style type="scss">
.updateCard {
  height: 150px;
  border-radius: 5px;
  box-shadow: 5px 5px 20px gray;
  padding: 20px;
  transition: all 100ms ease-in-out;
  p {
    margin-bottom: 2px;
    font-weight: 100;
  }
  .label {
    font-size: 12px;
    font-weight: 700;
    color: gray;
  }
  &:hover {
    background-color: rgba(0, 128, 0, 0.301);
  }
}
</style>
