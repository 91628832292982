<template>
  <div>
    <div
      :class="{ loading: true, 'loading-active': loaderVisible }"
      v-if="loaderActive"
    >
      <img width="350" src="@/assets/ZE_Loading.gif" />
    </div>
    <div class="landing" id="home">
      <div class="landing-background">
        <div class="landing-background-color"></div>
      </div>
      <div class="landing-text row">
        <div class="landing-logo"></div>
        <div class="landing-header">Stranica Ne Postoji | 404</div>
        <b-btn
          variant="success"
          @click="$router.back()"
          style="margin-left: 160px"
          size="lg"
          >Nazad</b-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
  components: {},
  async beforeCreate() {},
  async created() {},
  async mounted() {
    window.addEventListener("scroll", this.updateScroll);
    console.log("Not Found!");
    this.getData();
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.contact {
  width: 80vw;
  .contact-form {
    max-width: 800px;
  }
}
.news-link {
  color: green;
  border: 1px solid green;
  border-radius: 5px;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  &:hover {
    text-decoration: none;
    background-color: green;
    color: white;
  }
}
.footer {
  background-color: rgb(20, 20, 20);
  height: auto;
  padding-top: 50px;
  padding-bottom: 10px;
  color: white;
  width: 100%;
  background-color: black;
  color: rgb(129, 139, 130);
  padding: 50px;
  z-index: 1000;
}
.footer {
  .contactf {
    * {
      text-align: left;
    }
    h6 {
      text-transform: uppercase;
      font-weight: bold;
      color: rgb(82, 82, 82);
    }
    a {
      color: rgb(167, 167, 167);
    }
  }
}
.form {
  position: absolute;
  top: 90vh;
  left: 0px;
  right: 0px;
  background-color: white;
}
.form-container {
  margin-bottom: 100px;
  margin-top: -150px;
}
.btn-home {
  position: absolute;
  top: 5px;
  left: 15px;
}
.btn-add {
  position: absolute;
  top: 5px;
  right: 15px;
}
.ticket {
  font-size: 0.875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    transform 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.has-success:after,
.has-danger:after {
  font-family: "nucleo";
  content: "\EA1B";
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 10px;
  color: #00f2c3;
  font-size: 11px;
}
.bg-primary {
  position: absolute !important;
}
.bg-rotate:after {
  content: "";
  position: absolute;
  top: 80%;
  left: 0;
  height: 100%;
  width: 150%;
  background: #1e1e2f !important;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
.about {
}
.landing {
  height: 80vh;
  .landing-background,
  .landing-background-color {
    position: fixed;
    left: -1vw;
    top: -1vh;
    width: 102vw;
    height: 102vh;
    background-color: #115d33;
    background-image: linear-gradient(60deg, #115d33, #228b22);
    background-position: 100% 10%;
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(4px);
  }
  .landing-background {
    background-image: url("@/assets/img/DJI_0313.JPG");
  }
  .landing-background-color {
    opacity: 0.7;
    top: 0;
  }
  .landing-text {
    color: white;
    position: absolute;
    top: 20vh;
    left: 15vw;
    width: calc(130px + 40vw);
    height: calc(40px + 8vw);
    padding: 20px;
    border-left: 2px solid white;
    text-wrap: nowrap;
    .landing-logo {
      background-image: url("@/assets/logo.png");
      background-position: 0% 0%;
      background-size: contain;
      background-repeat: no-repeat;
      height: calc(10px + 7vw);
      width: calc(10px + 7vw);
      position: absolute;
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222) hue-rotate(30deg);
    }
    .landing-header {
      margin-left: calc(20px + 7vw);
      margin-top: -10px;
      color: white;
      font-weight: bold;
      text-align: left;
      font-size: calc(4vw + 10px);
      filter: drop-shadow(2px 2px 5px #222);
    }
    .landing-subheader {
      font-size: calc(0.2vw + 10px);
      text-align: left;
      margin-left: calc(20px + 7vw);
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222);
    }
  }
}
</style>
