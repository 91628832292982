<template>
  <div style="z-index: 100; background-color: white">
    <meta name="viewport" content="width=800,initial-scale=1.0" />
    <div v-if="user" class="background-color">
      <b-container fluid="lg"><router-view></router-view></b-container>
    </div>
    <SideBar
      v-if="user && tabs"
      :user="user"
      :tabs="tabs"
      :notifications="notifications.filter((el) => el.seen == false).length"
    ></SideBar>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService.js";
import SideBar from "@/components/SideBar.vue";

export default {
  name: "PanelLayout",
  components: { SideBar },
  data() {
    return {
      isLoggedIn: false,
      user: null,
      notifications: [],
      interval: null,
      tabs: [
        {
          routeName: "home",
          url: "/",
          icon: "house",
          name: "Home",
        },
        {
          routeName: "updates",
          url: "/admin/updates",
          icon: "journal-bookmark",
          name: "Updates",
        },
        {
          routeName: "notifications",
          url: "/admin/notifications",
          icon: "bookmark",
          name: "Notifications",
        },
        {
          routeName: "posts",
          url: "/admin/posts",
          icon: "newspaper",
          name: "Posts",
        },
        {
          routeName: "forms",
          url: "/admin/forms",
          icon: "bank",
          name: "Submissions",
          permission: "admin.forms",
        },
        {
          routeName: "messages",
          url: "/admin/messages",
          icon: "chat-left-text",
          name: "Messages",
          permission: "admin.messages",
        },
        {
          routeName: "panelportfolio",
          url: "/admin/portfolio",
          icon: "images",
          name: "Portfolio",
          permission: "admin.portfolio",
        },
        {
          routeName: "users",
          url: "/admin/users",
          icon: "person",
          name: "Manage Users",
          permission: "admin.users",
        },
        {
          routeName: "roles",
          url: "/admin/roles",
          icon: "hammer",
          name: "Manage Roles",
          permission: "admin.roles",
        },
      ],
    };
  },
  async mounted() {
    this.isLoggedIn = !!this.$store.getters.isLoggedIn;
    console.log(this.isLoggedIn);
    if (this.isLoggedIn) {
      var data = await AuthService.userDetails().catch((err) => {
        console.log(err);
        this.$store.dispatch("logout");
        this.isLoggedIn = false;
        if (err.response.status == 403) {
          this.$router.push({
            path: "/",
          });
        } else {
          this.$router.push({
            path: "/",
          });
        }
      });
      this.user = data;
    } else {
      this.$router.push({
        path: "/login",
      });
    }
  },
  beforeDestroy() {},
  methods: {
    logout() {
      this.$store.dispatch("logout");
      window.location.href = "/";
    },
  },
};
</script>
<style type="scss" scoped>
body {
}
.background-color {
  z-index: 1;
  position: absolute;
  top: 0px;
  height: 100vh;
  right: 0px;
  left: 0px;
  padding-top: 100px;
  background: rgb(255, 255, 255);

  overflow-y: auto;
  overflow-x: hidden;
}
</style>
